export const columns = [
	{
		title: "问卷标题",
		width: "15%",
		dataIndex: "title",
	},
	{
		title: "问卷对象",
		width: "9%",
		dataIndex: "answerType",
		customRender: function(answerType) {
			switch(answerType) {
				case 1: return '无限制';
				case 2: return '业主';
				case 3: return '业主亲属';
				case 4: return '租户';
				case 5: return '租户亲属';
			}
		}
	},
	{
		title: "状态",
		width: "9%",
		dataIndex: "status",
		customRender: function(status) {
			switch(status) {
				case 1: return '未开始';
				case 2: return '正在进行';
				case 3: return '已结束';
			}
		}
	},
    {
		title: "开始时间",
		width: "15%",
		dataIndex: "beginDate",
	},
	{
		title: "结束时间",
		width: "15%",
		dataIndex: "endDate",
	},
	{
		title: "答题人数",
		width: "8%",
		dataIndex: "answerNum",
	},
	{
		title: "是否发布",
		width: "10%",
		dataIndex: "isRelease",
		scopedSlots: { customRender: "release" },
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "240",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]

export const rules = {
	appUserId: [{ required: true, message: "请选择住户", trigger: "change" }],
	industryCommitteeTypeId: [{ required: true, message: "请选择职位", trigger: "change" }],
}