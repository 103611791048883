<template>
    <div>
        <div class="cardTitle">
            <a-space size="large">
                <span>问卷调查</span>
            </a-space>
        </div>
        <div class="search-box">
            <a-row>
                <a-col :span="20">
                <a-space size="large">
                    <a-input v-model="form.title" style="width: 200px" placeholder="问卷标题"></a-input>
                    <a-select v-model="form.status" style="width: 200px" placeholder="问卷状态">
                        <a-select-option :value="1">未开始</a-select-option>
                        <a-select-option :value="2">正在进行</a-select-option>
                        <a-select-option :value="3">已结束</a-select-option>
                    </a-select>
                    <a-select v-model="form.answerType" style="width: 200px" placeholder="问卷对象">
                        <a-select-option :value="1">无限制</a-select-option>
                        <a-select-option :value="2">业主</a-select-option>
                        <a-select-option :value="3">业主亲属</a-select-option>
                        <a-select-option :value="4">租户</a-select-option>
                        <a-select-option :value="5">租户亲属</a-select-option>
                    </a-select>
                    <a-button type="primary" @click='getData'>查 询</a-button>
                    <a-button @click='reset'>重 置</a-button>
                </a-space>
                </a-col>
            </a-row>
        </div>
        <a-button style="margin: 10px" class="add-btn" @click="addScroll">新增问卷调查</a-button>
        <div class="main">
            <a-table :columns="columns" :data-source="tableData" 
                :pagination="pagination" @change="handlerChange"
                :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: selectionChoosed,
                }"
                :row-key="
                (record, index) => {
                return record.id;
                }">
                <template slot="release" slot-scope="text,record">
                    <a-switch checked-children="已发布"
                        un-checked-children="未发布"
                        :checked="record.isRelease == 1"
                        @change="handleRelease(record)">
                    </a-switch>
                </template>
                <template slot="action" slot-scope="text,record">
                    <span><a @click=analize(record)>分析报表</a></span>
                    <span><a style="margin-left: 8px" @click=edit(record)>编辑</a></span>
                    <span><a style="margin-left: 8px;color:red" @click=del(record)>删除</a></span>
                </template>
            </a-table>
            <div class="action">
                <a-dropdown :disabled="!hasSelected">
                    <a-menu slot="overlay" @click="handleMenuClick">
                    <a-menu-item key="del"> 批量删除 </a-menu-item>
                    </a-menu>
                    <a-button> 批量操作 <a-icon type="down" /> </a-button>
                </a-dropdown>
                <span style="margin-left: 8px">
                    <template v-if="hasSelected">
                    {{ `已选择 ${selectedRowKeys.length} 条` }}
                    </template>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { rules, columns } from "./depend/config";
import {getQuestionList, delQuestion, releaseQuestion} from "@/api/operation/scroll"
export default {
    data() {
        return {
            form: {
                title: '',
                status: undefined,
                answerType: undefined
            },
            rules: rules,
            columns: columns,
            tableData: [],
            pagination: {
                current: 1,
                total: 0,
                pageSize: 10,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            //批量删除
            selectedRowKeys: [],
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            let obj = Object.assign(this.form, {pageNum: this.pagination.current,size: this.pagination.pageSize})
            getQuestionList(obj).then(res => {
                this.tableData = res.data.rows;
                this.pagination.total = res.data.total
            })
        },
        reset() {
            this.form = {
                title: '',
                status: undefined,
                answerType: undefined
            },
            this.getData()
        },
        handlerChange() {
            this.pagination.current = val.current;
            this.pagination.pageSize = val.pageSize;
            this.getData()
        },
        //报表分析
        analize(val) {
            this.$router.push({name:'analyze',params:{id:val.id}})
        },
        //编辑
        edit(val) {
            this.$router.push({name:'editScroll',params:{id:val.id}})
        },
        //发布
        handleRelease(val) {
            releaseQuestion({questionnaireId: val.id}).then(res => {
                if(res.code === 200){
                    this.$message.success(res.msg);
                    this.getData()
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        //删除
        del(val) {
            this.$confirm({
                title: "是否删除该问卷？",
                icon:'delete',
                onOk:async()=>{
                    let res = await delQuestion({questionnaireIds: [val.id]})
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.getData()
                    } else {
                        this.$message.error(res.msg);
                    }
                },
            })
        },
        //批量删除
        selectionChoosed(val) {
            this.selectedRowKeys = val
        },
        handleMenuClick(data) {
            if (data.key === "del") {
                this.$confirm({
				title: "是否删除选中的问卷？",
				icon:'delete',
				onOk:async()=>{
                        let res = await delQuestion({questionnaireIds: this.selectedRowKeys})
                        if(res.code === 200){
                            this.$message.success(res.msg);
                            this.selectedRowKeys = [];
                            this.getData()
                        } else {
                            this.$message.error(res.msg);
                        }
                    },
                })
			}
        },
        // 新增问卷
        addScroll() {
            this.$router.push({name:'addScroll'})
        }
    },
    computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
}
</script>

<style lang="less">
.drawer-content {
    padding: 16px;
}
</style>